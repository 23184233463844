import { Environment } from './environment.interface';

export const environment: Environment = {
  production: false,
  pwa: true,
  gtmId: 'GTM-5WHKQDVW',
  apiEndpoint: 'https://app-globaldashboard-api-int.azurewebsites.net',
  auth0: {
    domain: 'auth-uat.tnssng.com',
    clientId: 'L5EYwWJTf5MJ3cC3vIYe6wRvX8kXWPYb',
    authorizationParams: {
      redirect_uri: `${window.location.origin}/callback`,
      audience: 'https://app-globaldashboard-api-int.azurewebsites.net',
    },
    errorPath: '/error',
    httpInterceptor: {
      allowedList: ['https://app-globaldashboard-api-int.azurewebsites.net/*'],
    },
  },
};
